/* eslint-disable react/jsx-no-constructed-context-values */
import React from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { useSelector } from 'react-redux';

import { BrandingType } from '@ha/api/v2/types/UniversityInfo';
import { isHaMobileApp } from '@ha/core/MobileApp';
import { IconButton } from '@hbf/dsl/core';
import { ArrowLeft } from '@hbf/icons/brand-bold';

import { useIntl } from 'ha/i18n';
import {
  getIsAdvertiser,
  getIsAuthenticated,
} from 'ha/modules/AuthLogic/selectors';

import { SmartTrack as Track } from 'ha/components/track/SmartTrack';

import { usePage } from '../Page/contexts';

import { AdminImpersonationBanner } from './components/AdminImpersonationBanner/AdminImpersonationBanner';
import { LoadableAdvertiserQuickActions } from './components/AdvertiserQuickActions/AdvertiserQuickActions.lazy';
import { CoBrandedLogo } from './components/CobrandedLogo';
import { HeaderQuickActions } from './components/HeaderQuickActions/HeaderQuickActions';
import { LoggedOutQuickActions } from './components/LoggedOutQuickActions/LoggedOutQuickActions';
import { NavigationAppBar } from './components/NavigationAppBar/NavigationAppBar';
import {
  NavigationLogo,
  NavigationLogoProps,
} from './components/NavigationLogo/NavigationLogo';
import { LoadableTenantQuickActions } from './components/TenantQuickActions/TenantQuickActions.lazy';

export const DefaultLogo = ({ size }: Pick<NavigationLogoProps, 'size'>) => {
  const { urlResolver } = useIntl();

  const isAdvertiser = useSelector(getIsAdvertiser);

  return (
    <Track name="Clicked Logo in header" type="icon">
      <a
        href={urlResolver.getHomeUrl({ explicitNoRedirect: isAdvertiser })}
        data-test-locator="Navigation/LogoHomePageLink"
      >
        <NavigationLogo size={size} />
      </a>
    </Track>
  );
};

const DefaultQuickActions = () => {
  const isAuthenticated = useSelector(getIsAuthenticated);
  const isAdvertiser = useSelector(getIsAdvertiser);

  if (isAuthenticated) {
    if (isAdvertiser) return <LoadableAdvertiserQuickActions />;
    return <LoadableTenantQuickActions />;
  }

  return <LoggedOutQuickActions />;
};

interface NavigationHeaderProps {
  sticky?: boolean;
  slots?: {
    appBar?: typeof NavigationAppBar;

    logo?: React.ReactNode;
    dynamicIsland?: React.ReactNode;
    quickActions?: React.ReactNode;

    above?: React.ReactNode;
    below?: React.ReactNode;
  };
}

export const NavigationHeader = ({
  sticky = false,
  slots = {},
}: NavigationHeaderProps) => {
  const { appBar: AppBar = NavigationAppBar } = slots;
  const { partnerInfo } = usePage();

  const { urlResolver } = useIntl();

  const navigate = useNavigate();
  const location = useLocation();

  const logoSlot = (() => {
    const isHomePage = location.pathname === '/';
    const isInboxPage = location.pathname.includes('/my/inbox');

    const goBackHandler = () => {
      if (isInboxPage) {
        return navigate(urlResolver.getHomeUrl({ explicitNoRedirect: true }));
      }

      return navigate(-1);
    };

    if (partnerInfo && partnerInfo.brandingType === BrandingType.CoBranded) {
      return <CoBrandedLogo partnerInfo={partnerInfo} />;
    }

    const shouldRenderBackButton = Boolean(isHaMobileApp() && !isHomePage);

    return shouldRenderBackButton ? (
      <IconButton onClick={goBackHandler}>
        <ArrowLeft />
      </IconButton>
    ) : (
      slots.logo ?? <DefaultLogo />
    );
  })();

  return (
    <React.Fragment>
      <AdminImpersonationBanner />
      <AppBar
        sticky={sticky}
        slots={{
          above: slots.above,
          below: slots.below,
          logo: logoSlot,
          dynamicIsland: slots.dynamicIsland,
          quickActions: slots.quickActions ?? (
            <HeaderQuickActions.Context.Provider
              value={{ hasSidebar: false, showCTA: true }}
            >
              <DefaultQuickActions />
            </HeaderQuickActions.Context.Provider>
          ),
        }}
      />
    </React.Fragment>
  );
};

NavigationHeader.DefaultLogo = DefaultLogo;
NavigationHeader.DefaultQuickActions = DefaultQuickActions;
