import React from 'react';

import { useMediaQuery, useTheme } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

import { BrandingType } from '@ha/api/v2/types/UniversityInfo';
import { Button } from '@hbf/dsl/core';

import { HOME } from 'ha/constants/pageNames';

import { useIntl } from 'ha/i18n';

import { SmartTrack as Track } from 'ha/components/track/SmartTrack';
import { LanguageSwitcher } from 'ha/modules/LanguageSwitcher/components/LanguageSwitcher';
import { usePage } from 'ha/modules/Page/contexts/PageContext';

import { AuthButtonLogin } from '../AuthButtonLogin/AuthButtonLogin';
import { AuthButtonRegister } from '../AuthButtonRegister/AuthButtonRegister';
import { CallToActionButton } from '../CallToActionButton/CallToActionButton';
import { DynamicIslandContext } from '../HeaderDynamicIsland/context';
import { HeaderQuickActions } from '../HeaderQuickActions/HeaderQuickActions';
import { LoggedOutQuickActionsDrawer } from '../LoggedOutQuickActionsDrawer/LoggedOutQuickActionsDrawer';
import { LoggedOutQuickActionsMore } from '../LoggedOutQuickActionsMore/LoggedOutQuickActionsMore';
import { OnboardingHelp } from '../OnboardingLinks/OnboardingHelp';

interface LoggedOutActionsProps {
  slots?: {
    onboardingLinks?: React.ReactNode;
  };
}

const useStyles = makeStyles()(theme => ({
  container: {
    display: 'flex',
    gap: theme.spacing(6),
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
  },
  drawerAuthContainer: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gap: theme.spacing(5),
  },
  drawerOnboardingContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

interface LoggedOutQuickActionsHeaderProps {
  compact: boolean;
}

const LoggedOutQuickActionsOnboardingHeader = ({
  compact,
}: LoggedOutQuickActionsHeaderProps) => {
  const { T, urlResolver } = useIntl();

  const { category, name, partnerInfo } = usePage();

  const isCoBranded =
    partnerInfo && partnerInfo.brandingType === BrandingType.CoBranded;

  return (
    <React.Fragment>
      <Track
        name="Clicked How it works in NavBar"
        type="tenant"
        pageCategory={category}
      >
        <Button
          component="a"
          variant="text"
          color="neutral"
          href={urlResolver.getRenting()}
          rel="noopener noreferrer"
          target={isCoBranded ? '_blank' : undefined}
        >
          {T('nav.header.how_it_works')}
        </Button>
      </Track>

      {!compact && (
        <React.Fragment>
          <Track
            name="Clicked PricingLink in NavBar"
            type="tenant"
            pageCategory={category}
          >
            <Button
              component="a"
              variant="text"
              color="neutral"
              href={urlResolver.getPricingTenantsUrl()}
              rel={
                name !== HOME
                  ? 'noopener noreferrer no-follow'
                  : 'noopener noreferrer'
              }
              target={isCoBranded ? '_blank' : undefined}
            >
              {T('nav.header.pricing')}
            </Button>
          </Track>

          <OnboardingHelp.Track>
            <OnboardingHelp.Link
              component={Button}
              variant="text"
              color="neutral"
              target={isCoBranded ? '_blank' : undefined}
            />
          </OnboardingHelp.Track>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const LoggedOutQuickActionsAuthHeader = ({
  compact,
}: LoggedOutQuickActionsHeaderProps) => {
  const { category } = usePage();

  return (
    <React.Fragment>
      {!compact && (
        <Track name="Clicked Login in NavBar" pageCategory={category}>
          <AuthButtonLogin component={Button} variant="text" color="neutral" />
        </Track>
      )}

      <Track name="Clicked Register in NavBar" pageCategory={category}>
        <AuthButtonRegister component={Button} variant="text" color="neutral" />
      </Track>
    </React.Fragment>
  );
};

export const LoggedOutQuickActions = ({ slots }: LoggedOutActionsProps) => {
  const { T, urlResolver } = useIntl();

  const { category } = usePage();

  const { classes } = useStyles();

  const theme = useTheme();

  const { showCTA } = HeaderQuickActions.Context.useContext();
  const { isDynamicIslandShown } = DynamicIslandContext.useContext();

  const isLargerThanSm = useMediaQuery(theme.breakpoints.up('sm'));
  const isLargerThanMd = useMediaQuery(theme.breakpoints.up('md'));

  const shouldCollapseActions = isLargerThanMd && isDynamicIslandShown; // only collapse if the extra space is needed

  return (
    <div className={classes.container}>
      {isLargerThanSm && (
        <HeaderQuickActions>
          {isLargerThanMd && (
            <React.Fragment>
              {slots?.onboardingLinks ?? (
                <HeaderQuickActions.Block>
                  <LoggedOutQuickActionsOnboardingHeader
                    compact={shouldCollapseActions}
                  />
                </HeaderQuickActions.Block>
              )}

              <HeaderQuickActions.Block>
                <LoggedOutQuickActionsAuthHeader
                  compact={shouldCollapseActions}
                />
              </HeaderQuickActions.Block>

              {shouldCollapseActions && <LoggedOutQuickActionsMore />}

              {showCTA && (
                <Track
                  name="Clicked I am a landlord in header"
                  type="button"
                  pageCategory={category}
                >
                  <CallToActionButton
                    href={urlResolver.getPrivateLandlordLandingPageUrl()}
                  >
                    {T('nav.header.landlord')}
                  </CallToActionButton>
                </Track>
              )}
            </React.Fragment>
          )}

          {isLargerThanSm && <LanguageSwitcher size="icon" />}
        </HeaderQuickActions>
      )}

      {!isLargerThanMd && <LoggedOutQuickActionsDrawer />}
    </div>
  );
};

// eslint-disable-next-line import/no-default-export
export default LoggedOutQuickActions;
