import React from 'react';

import { Environment } from 'ha/config';

import { useConfig } from 'ha/helpers/config';

const getDataSize = (data: unknown) => {
  if (data === undefined) return 0;
  const totalDataInBytes = new TextEncoder().encode(
    JSON.stringify(data),
  ).length;
  return totalDataInBytes / 1024; // Return size in KB
};

const PAGE_SIZE_THRESHOLD = 128;

type GlobalKeys = keyof typeof globalThis;

const GLOBAL_KEYS: GlobalKeys[] = [
  '__MAPS_SERVICES_CONFIG__',
  '__PRELOADED_CONFIG__',
  '__PRELOADED_STATE__',
  '__staticRouterHydrationData',
  '__CACHED_DATA__',
];

export const usePageSizeLogger = () => {
  const { env } = useConfig();
  const isDevelopment = env === Environment.DEVELOPMENT;

  React.useEffect(() => {
    if (!isDevelopment) return;

    const dataSizes = GLOBAL_KEYS.reduce((acc, key) => {
      acc[key] = getDataSize(global[key as keyof typeof global]).toFixed(2);
      return acc;
    }, {} as Record<GlobalKeys, string>);

    const totalPageData = Object.values(dataSizes).reduce(
      (sum, size) => sum + Number(size),
      0,
    );

    if (totalPageData > PAGE_SIZE_THRESHOLD) {
      console.log(
        `The size of data in page: ${totalPageData.toFixed(
          2,
        )} KB, You can see all the data in the following table`,
      );
      console.table(dataSizes);
    }
  }, [isDevelopment]);
};
