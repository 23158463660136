import React, { ImgHTMLAttributes } from 'react';

import cx from 'classnames';

import 'lazysizes';
import ImgixComponent, { SharedImigixAndSourceProps } from 'react-imgix';

import NoPhotoImg from 'ha/img-src/static/nophotos.png';

import { isValidImgixSource } from './isValidImgixSource';
import { useImgix } from './useImgix';

type DimensionProps = {
  width: number;
  height?: number;
};

type ImgixSizes = Omit<SharedImigixAndSourceProps, 'width' | 'height'> & {
  sizes: string;
};

type ImgixWidth = Omit<SharedImigixAndSourceProps, 'sizes'> & DimensionProps;

export type ImgixProps = (ImgixSizes | ImgixWidth) & {
  fallbackSrc?: string;
  isLazy?: boolean;
  useLowQualityImagePlaceholder?: SharedImigixAndSourceProps['imgixParams'] &
    Partial<DimensionProps>;
};

/**
 * The classname that lazysizes library uses.
 * @see [lazysizes](https://github.com/aFarkas/lazysizes)
 */
const LAZY_LOAD_CLASS_NAME = 'lazyload';
const lazyImgixConfig = {
  src: 'data-src',
  srcSet: 'data-srcset',
  sizes: 'data-sizes',
};

export const Imgix: React.FC<ImgixProps> = props => {
  const { transformImageURL } = useImgix();
  const [fallback, setFallback] = React.useState<boolean>(false);

  const showFallback = React.useCallback(() => {
    setFallback(true);
  }, [setFallback]);

  const styles = props.isLazy
    ? cx(props.className, LAZY_LOAD_CLASS_NAME)
    : props.className;

  const { src = NoPhotoImg, fallbackSrc = NoPhotoImg } = props;
  if (!props.src || !isValidImgixSource(props.src) || fallback) {
    const srcUrl = fallback ? fallbackSrc : src || fallbackSrc;

    return (
      // eslint-disable-next-line jsx-a11y/alt-text
      <img
        {...(props.htmlAttributes as ImgHTMLAttributes<HTMLImageElement>)}
        data-test-locator={
          props?.htmlAttributes?.['data-test-locator'] || 'imgix'
        }
        alt={
          (props?.htmlAttributes as ImgHTMLAttributes<HTMLImageElement>)?.alt ||
          ''
        }
        {...(props.isLazy
          ? {
              'data-src': srcUrl,
              'data-sizes': (props as ImgixSizes).sizes,
            }
          : {
              src: srcUrl,
              sizes: (props as ImgixSizes).sizes,
            })}
        width={(props as ImgixWidth).width}
        className={styles}
        onError={showFallback}
      />
    );
  }

  const lowQualityImagePlaceholder =
    props.isLazy && props.useLowQualityImagePlaceholder
      ? transformImageURL(src, {
          ...props.imgixParams,
          blur: 200,
          px: 16,
          q: 5,
          auto: 'format',
          width: (props as ImgixWidth).width,
          height: (props as ImgixWidth).height,

          // optional overrides dedicated for the LQIP image
          ...props.useLowQualityImagePlaceholder,
        } as SharedImigixAndSourceProps['imgixParams'])
      : undefined;

  return (
    <ImgixComponent
      {...props}
      className={styles}
      htmlAttributes={{
        'data-test-locator': 'imgix',
        onError: showFallback,
        ...(lowQualityImagePlaceholder && { src: lowQualityImagePlaceholder }),
        ...props.htmlAttributes,
      }}
      attributeConfig={props.isLazy ? lazyImgixConfig : {}}
      src={transformImageURL(src)}
    />
  );
};
