export enum UnitTypeVisibility {
  UnitTypeVisibilityAll = 0,
  UnitTypeVisibilityExclusiveOnly = 1,
}

export enum BrandingType {
  UniversitySignupPage = 'university_signup_page',
  CoBranded = 'co_branded',
}

export enum PartnershipType {
  PartneredWith = 'partnered_with',
  PoweredBy = 'powered_by',
}

export enum PartnerCategory {
  University = 'university',
  Institution = 'educational_institution',
  Commercial = 'commercial_partner',
  Student = 'student_organisation',
  DemandMarketing = 'demand_marketing',
  SupplyMarketing = 'supply_marketing',
}

export interface UniversityInfo {
  id: number;
  name: string;
  website: string;
  contactEmail: string;
  phone: string;
  iconUrl: string;
  footerImageUrl?: string;
  headerImageUrl?: string;
  description: string;
  country_code: string;
  category: PartnerCategory;
  unitTypeVisibility: UnitTypeVisibility;
  brandingType: BrandingType;
  partnershipType: PartnershipType;
  exclusiveAdvertiserIds: number[];
  defaultFilterValue: string;
  cityGeonameId?: number;
  lat?: number;
  lng?: number;
}
