import { FetchStatus } from '../errors/FetchStatus';

export type UserKYCEntityType = 'natural' | 'legal';

export type UserKYCDocumentType =
  | 'address-proof'
  | 'articles-of-association'
  | 'identity-proof'
  | 'registration-proof'
  | 'shareholder-declaration';

export type UserKYCEntityLegalType =
  | 'business'
  | 'organization'
  | 'sole-trader';

export type Representative = {
  firstName: string;
  lastName: string;
  birthday: string;
  nationalityCC: string;
  residenceCC: string;
  email: string;
  birthCity: string;
  birthCC: string;
  isRepresentative: boolean;
  isShareholder: boolean;
  isExecutive: boolean;
  address: {
    line1: string;
    line2: string;
    city: string;
    postal: string;
    countryCode: string;
    region: string;
    createdAt?: string;
  };
  createdAt?: string;
};

export type KYCNatural = Representative;

export type KYCLegal = {
  legalType: UserKYCEntityLegalType;
  name: string;
  email: string;
  companyNumber: string;
  address: {
    line1: string;
    line2: string;
    city: string;
    postal: string;
    countryCode: string;
    region: string;
    createdAt?: string;
  };
  people: Representative[];
  createdAt?: string;
};

export type KYCEntity = {
  accountUuid: string;
  countryCode: string;
  createdAt?: string;
  customerType: UserKYCEntityType;
  isEditable?: boolean;
  kycStatus: UserKYCStatus;
  lastSubmittedAt?: string;
  legal?: Partial<KYCLegal>;
  natural?: Partial<KYCNatural>;
  updatedAt?: string;
  uuid: string;
  nextSteps?: {
    action: 'redirect';
    description: 'kyc-onboarding';
    redirectUrl: string;
  }[];
};

export type GetUserKYCsResponse = {
  count: number;
  entities: KYCEntity[];
};

export type PostUserKYCPayload = Pick<
  KYCEntity,
  'customerType' | 'countryCode' | 'natural' | 'legal'
>;

export type PostUserKYCResponse = KYCEntity;

export type UpdateKYCStatusPayload = {
  grantConsent: boolean;
  kycStatus: UserKYCStatus;
};

export type KycDocument = {
  documentUuid: string;
  createdAt: string;
  mimeType: string;
  filename: string;
  size: number;
  title: string;
};

export type KycDocuments = {
  [key: string]: KycDocument[];
};

export type KycDocumentsResponse = {
  kycUuid: string;
  documents: KycDocuments;
};

export type KycCountries = {
  acceptedCountryCodes: string[];
};

export type KycRequirements = {
  hasExpiringRequirements: boolean;
  requirementsDeadlineAt: string;
};

export type KycInfo = {
  kycDone: boolean;
  kycStarted: boolean;
  requirements: KycRequirements;
};

export enum KycStates {
  EXPIRING = 'expiring',
  MISSING_DATA = 'missing-data',
  NOT_VERIFIED = 'not-verified',
  PENDING = 'pending',
  VERIFIED = 'verified',
  UNKNOWN = 'unknown',
}

export enum UserKYCStatus {
  PENDING = 'pending',
  READY_FOR_REVIEW = 'ready-for-review',
  INTERNALLY_ACCEPTED = 'internally-accepted',
  INCORRECT = 'incorrect',
  EXTERNAL_REVIEW_REQUESTED = 'external-review-requested',
  SEND_FAILED = 'send-failed',
  IN_EXTERNAL_REVIEW = 'in-external-review',
  EXTERNALLY_ACCEPTED = 'externally-accepted',
  EXTERNALLY_REFUSED = 'externally-refused',
  CHANGES_REQUESTED = 'changes-requested',
}

export interface KycInformationWithFetchStatus extends KycInfo {
  fetchStatus: FetchStatus;
}
