import React from 'react';

import { BrandingType } from '@ha/api/v2/types/UniversityInfo';
import { IconButton, List } from '@hbf/dsl/core';
import { Menu, Login } from '@hbf/icons/brand-bold';
import { Payments, Help } from '@hbf/icons/brand-regular';

import { HOME } from 'ha/constants/pageNames';

import { useIntl } from 'ha/i18n';

import { SmartTrack as Track } from 'ha/components/track/SmartTrack';
import { usePage } from 'ha/modules/Page/contexts/PageContext';

import { AuthButtonLogin } from '../AuthButtonLogin/AuthButtonLogin';
import { NavigationDropdownMenu } from '../NavigationDropdownMenu/NavigationDropdownMenu';
import { OnboardingHelp } from '../OnboardingLinks/OnboardingHelp';

export const LoggedOutQuickActionsMore = () => {
  const { T, urlResolver } = useIntl();
  const { name, partnerInfo, category } = usePage();

  const isCoBranded =
    partnerInfo && partnerInfo.brandingType === BrandingType.CoBranded;

  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const burgerMenu = React.useRef(null);

  const handleMenuClick = () => setIsMenuOpen(state => !state);

  return (
    <React.Fragment>
      <IconButton
        ref={burgerMenu}
        onClick={handleMenuClick}
        aria-label="Menu button"
        data-test-locator="Navigation/MoreActionsButton"
      >
        <Menu />
      </IconButton>

      <NavigationDropdownMenu
        anchorEl={burgerMenu.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={isMenuOpen}
        onClose={() => handleMenuClick()}
        data-test-locator="Navigation/MoreActionsMenu"
      >
        <List size="md">
          <li>
            <Track name="Clicked Login in NavBar" pageCategory={category}>
              <AuthButtonLogin component={List.Item} startIcon={<Login />} />
            </Track>
          </li>

          <li>
            <Track
              name="Clicked PricingLink in NavBar"
              type="tenant"
              pageCategory={category}
            >
              <List.Item
                component="a"
                href={urlResolver.getPricingTenantsUrl()}
                rel={
                  name !== HOME
                    ? 'noopener noreferrer no-follow'
                    : 'noopener noreferrer'
                }
                startIcon={<Payments />}
                target={isCoBranded ? '_blank' : undefined}
              >
                {T('nav.header.pricing')}
              </List.Item>
            </Track>
          </li>

          <li>
            <OnboardingHelp.Track>
              <OnboardingHelp.Link
                component={List.Item}
                startIcon={<Help />}
                rel="noopener noreferrer"
                target={isCoBranded ? '_blank' : undefined}
              >
                <OnboardingHelp.Label />
              </OnboardingHelp.Link>
            </OnboardingHelp.Track>
          </li>
        </List>
      </NavigationDropdownMenu>
    </React.Fragment>
  );
};
